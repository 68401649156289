$mid-grey: #aaaaaa;
$dark: #111111;
$primary: hsl(300, 100%, 67%);
$primary-light: hsl(300, 80%, 88%);

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,600;0,800;1,400&display=swap');

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

#root {
  height: 100%;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

h1 {
  font-family: 'Playfair Display', serif;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Set core body defaults */
body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

button {
  font-size: 16px;
}

#root {
  display: flex;
  justify-content: center;
}

/* App classes */
.App {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.75em;
  max-width: 640px;
}

.header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  max-width: 640px;
  height: 4em;
  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0.6em 0.4em;
  }
  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0.6em 0.4em;
  }
  &__center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4em;
  }
  &__fixed {
    position: fixed;
    top: 0;
    background-color: white;
    padding: 0 0.75em;
    z-index: 2;
  }
}

.work-tile {
  &__active {
    width: 100%;
    background-color: $dark;
    margin-bottom: 0.5em;
    cursor: pointer;
    position: relative;
    &__progress-bar {
      background-color: $primary;
      width: 0%;
      height: 3em;
      transition-timing-function: 'linear';
    }
    &__progress-bar-summary {
      background-color: $primary;
      width: 0%;
      height: 4em;
      transition-timing-function: 'linear';
    }
  }
  &__inactive {
    width: 100%;
    height: 3em;
    margin-bottom: 0.5em;
    border: 1px solid $mid-grey;
  }
  &__text {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    margin-top: 12px;
    margin-left: 40px;
    color: white;
  }
  &__text-summary {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    margin-top: 28px;
    margin-left: 40px;
    color: white;
  }
  &__summary-small-heading {
    position: absolute;
    color: white;
    font-size: 0.8rem;
    margin-top: 5px;
    margin-left: 8px;
  }
}

.play-svg {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  margin-top: 12px;
  margin-left: 8px;
}

.play-svg-summary {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  margin-top: 28px;
  margin-left: 8px;
}

.skipped-text {
  letter-spacing: 2px;
  font-weight: 500;
}

.answer-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: auto;
  padding-bottom: 1em;
  width: 100%;
  overflow: visible;
}

.bottom-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

button {
  border: none;
  padding: 0.8em 1.6em;
  color: white;
  letter-spacing: 2px;
  font-weight: 500;
  cursor: pointer;
  &.btn-mid {
    background-color: $dark;
  }
  &.btn-full {
    background-color: $primary;
  }
}

.summary-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 4em;
  &__img {
    width: 100px;
    height: 100px;
  }
  &__alert {
    display: none;
    position: fixed;
    top: 0.1em;
    width: 20em;
    margin: 1.2em;
    padding: 1em;
    text-align: center;
    background-color: white;
    border: 1px solid $mid-grey;
    -webkit-box-shadow: -11px 10px 38px -7px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: -11px 10px 38px -7px rgba(0, 0, 0, 0.25);
    box-shadow: -11px 10px 38px -7px rgba(0, 0, 0, 0.25);
  }
  &__section {
    width: 100%;
  }
  &__detail {
    border: 1px solid $primary;
    display: flex;
    padding: 0.8em;
    margin-bottom: 2em;
    color: $primary;
    border-radius: 0.6em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    -webkit-box-shadow: inset -5px -5px 11px -1px $primary-light;
    box-shadow: inset -5px -5px 11px -1px $primary-light;
    &__img-placeholder {
      width: 100px;
      height: 100px;
    }
    &__info-wrapper {
      flex: 1 0;
      margin-left: 1em;
      display: flex;
      flex-direction: column;
    }
    &__text {
      font-size: 0.8rem;
      margin-bottom: 0.25em;
    }
    &__title {
      font-size: 1rem;
      line-height: 1.2em;
    }
    &__sc-logo {
      margin-top: auto;
      align-self: flex-end;
    }
  }
}

.countdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3em 0;
}

.autocomplete-ac {
  width: calc(100% - 2px);
  margin: auto;
  font-size: 1.2rem;
  padding: 0.8em 0.5em;
  border-radius: 0;
  border: none;
  &:focus {
    border: none;
    outline: none;
  }
  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 0.5em;
    border: 1px solid $mid-grey;
  }
  &__suggestion-abs-wrapper {
    display: none;
    border-bottom: 1px solid $primary;
    bottom: 4em;
    z-index: 5;
  }
  &__suggestion {
    cursor: pointer;
    padding: 0.5em;
    background-color: white;
    &__highlight {
      background-color: $primary-light;
      padding: 0.2em 0;
    }
  }
  &__suggestion-info {
    padding: 0.2em 0.6em;
    font-size: 0.8rem;
    font-weight: 500;
    color: $primary;
  }
}

.modal {
  position: fixed;
  top: 0;
  width: 100%;
  height: 120%;
  background-color: #ffffff99;
  padding: 4em 1em 8em 1em;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  &__exit {
    position: absolute;
    right: 1em;
    top: 1em;
  }
  &__inner {
    width: 100%;
    max-width: 30em;
    background-color: white;
    border: 1px solid $mid-grey;
    padding: 1em 1.5em 2em 1.5em;
    position: relative;
    & > p {
      margin-bottom: 0.8em;
      color: $dark;
      font-size: 1.2rem;
    }
    &__heading {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1em;
    }
  }
}

.icon-button {
  cursor: pointer;
}

.distribution {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 2em 9em 2em 2em;
  justify-items: center;
  align-items: end;
  margin-bottom: 1em;
  &__bar {
    background-color: $dark;
    width: 35%;
    position: relative;
  }
  &__lose {
    background-color: $primary;
  }
  &__num-label {
    position: absolute;
    top: -1.8em;
    width: 100%;
    text-align: center;
  }
}

.stat-summary {
  margin-top: 1em;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  & > p {
    text-align: center;
    line-height: 1.2em;
  }
}

/** -- GRADIENTS FOR PLACEHOLDER ALBUM COVERS -- **/
.gradient-0 {
  background-color: #4158d0;
  background-image: linear-gradient(
    43deg,
    #4158d0 0%,
    #c850c0 46%,
    #ffcc70 100%
  );
}

.gradient-1 {
  background-color: #0093e9;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
}

.gradient-3 {
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
}

.gradient-2 {
  background-color: #85ffbd;
  background-image: linear-gradient(45deg, #85ffbd 0%, #fffb7d 100%);
}

.gradient-4 {
  background-color: #ffdee9;
  background-image: linear-gradient(0deg, #ffdee9 0%, #b5fffc 100%);
}

@media only screen and (max-width: 350px) {
  .modal {
    font-size: 0.9rem;
    &__inner {
      p {
        font-size: 1rem;
      }
    }
  }
}
